import { HttpApiService } from '~/services/http/HttpApiService'
import { HttpHandledResponse } from '~/services/http/types/Http.handledResponse'
import { Service } from '~/services/http/Service'
import { ChangeListPaginationResponse } from '~/modules/change-list/types/ChangeListPaginationResponse'

export class ChangeListHttpApiService extends Service {
  private endPoints = {
    firstPart: '/v4/estate-market/changelist',
    get: '/for-user',
    read: '/read-all-for-user',
    count: '/unread-count-for-user',
  }

  constructor(httpInstance: HttpApiService) {
    super(httpInstance)
  }

  getChangeList(nextPageUrl: string | null): Promise<HttpHandledResponse<ChangeListPaginationResponse>> {
    let url
    if (nextPageUrl) {
      url = nextPageUrl.replace('https://quick-realty.ru/api', '')
    }

    return this.httpInstance.get((url || nextPageUrl) || `${this.endPoints.firstPart}${this.endPoints.get}`)
  }

  readChangeList(): Promise<HttpHandledResponse<ChangeListPaginationResponse>> {
    return this.httpInstance.get(`${this.endPoints.firstPart}${this.endPoints.read}`)
  }

  getCountUnreadChangeList(): Promise<HttpHandledResponse<number>> {
    return this.httpInstance.get(`${this.endPoints.firstPart}${this.endPoints.count}`)
  }
}

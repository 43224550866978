import { ChangeListHttpApiService } from '~/modules/change-list/api/ChangeListHttpApiService'
import { ChangeListPaginationResponse } from '~/modules/change-list/types/ChangeListPaginationResponse'
import { useChangeListStore } from '~/modules/change-list/store'
import SentryLogger from '~/services/sentry/SentryLogger'

export class ChangeListService {
  http: ChangeListHttpApiService
  private store

  constructor() {
    const nuxtApp = useNuxtApp()
    const httpInstance = nuxtApp.$qdHttpApiInstance
    this.store = useChangeListStore()
    this.http = new ChangeListHttpApiService(httpInstance)
  }

  public getChangeList(): Promise<ChangeListPaginationResponse> {
    return new Promise((resolve, reject) => {
      const nextPageUrl = this.store.nextPageChangeListUrl
      this.http.getChangeList(nextPageUrl)
        .then(response => {
          const { data } = response
          this.store.setNextPageChangeListUrl(data.nextPageUrl)
          resolve(data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить обновления',
            nextPageUrl,
          })
          reject(error)
        })
    })
  }

  public readChangeList(): Promise<ChangeListPaginationResponse> {
    return new Promise((resolve, reject) => {
      this.http.readChangeList()
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось прочесть обновления',
          })
          reject(error)
        })
    })
  }

  public getCountUnreadChangeList(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.http.getCountUnreadChangeList()
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          SentryLogger.captureScopeException(error, {
            message: 'Не удалось получить количество непрочитанных обновлений',
          })
          reject(error)
        })
    })
  }
}
